import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import FleetFeetLogo from '../assets/fleet-feet-logo.png';

const ErrorPage = () => {
  const location = useLocation();
  const errorMessage = location.state?.message || 'An unexpected error occurred. Please try again later.';

  Sentry.captureException(new Error(errorMessage));  // Capture error message as exception

  return (
    <div className="app-container">
      <header className="header">
        <div className="header-content">
          <img src={FleetFeetLogo} alt="Fleet Feet Logo" className="header-logo" />
        </div>
      </header>

      <main className="error-content">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="error-title">Oops! Something went wrong</h2>
          <div className="error-message">
            <p>{errorMessage}</p>
          </div>
        </motion.div>
      </main>
    </div>
  );
};

export default ErrorPage;
