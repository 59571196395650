import React from 'react';
import { motion } from 'framer-motion';

const LoadingSpinner = () => (
  <div data-testid="loading-spinner" className="flex justify-center items-center h-64">
    <motion.div
      animate={{
        rotate: 360,
        borderRadius: ["50% 50% 50% 50%", "50% 50% 50% 70%", "50% 50% 70% 50%", "50% 70% 50% 50%", "70% 50% 50% 50%"],
      }}
      transition={{
        duration: 2,
        ease: "linear",
        repeat: Infinity,
      }}
      className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full"
    />
  </div>
);

export default LoadingSpinner;