import React, { useState } from 'react';
import { motion } from 'framer-motion';

const RewardCard = ({ reward }) => {
  const [copied, setCopied] = useState(false);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(reward.code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="reward-card"
    >
      <h3 className="reward-title">{reward.name}</h3>
      {reward.description && reward.description !== reward.name && (
        <p className="reward-description">{reward.description}</p>
      )}
      <p className="reward-validity">
        Valid until: {formatDate(reward.endDate)}
      </p>
      <div className="reward-code">{reward.code}</div>
      <button
        onClick={copyToClipboard}
        className={`copy-button ${copied ? 'copied' : ''}`}
      >
        {copied ? 'Copied!' : 'Copy Code'}
      </button>
    </motion.div>
  );
};

export default RewardCard;