import React from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useRewards } from '../hooks/useRewards';
import RewardCard from '../components/RewardCard';
import ErrorAlert from '../components/ErrorAlert';
import LoadingSpinner from '../components/LoadingSpinner';
import FleetFeetLogo from '../assets/fleet-feet-logo.png';
import '../styles/rewards.css';

const RewardsPage = ({ testToken }) => {
  const [searchParams] = useSearchParams();
  const token = testToken || searchParams.get('token');
  const { rewards, error, loading } = useRewards(token);

  return (
    <div className="app-container">
      <header className="header">
        <div className="header-content">
          <img src={FleetFeetLogo} alt="Fleet Feet Logo" className="header-logo" />
        </div>
      </header>

      <main className="main-content">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="rewards-title">Your Rewards</h2>
          
          {loading ? (
            <div className="loading-spinner">
              <LoadingSpinner />
            </div>
          ) : error ? (
            <div className="error-container">
              <ErrorAlert message={error} />
            </div>
          ) : rewards.length === 0 ? (
            <p className="no-rewards">No rewards available at the moment.</p>
          ) : (
            <div className="rewards-grid">
              {rewards.map((reward) => (
                <RewardCard key={reward.id} reward={reward} />
              ))}
            </div>
          )}
        </motion.div>
      </main>
    </div>
  );
};

export default RewardsPage;