import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const API_URL = 'https://api.fleetfeet.stedion.io/v1/checkout/rewards';

const formatCode = (rawCode) => {
  if (!rawCode) return '';
  const parts = rawCode.split('-');
  return parts.length > 1 ? parts.slice(1).join('-') : rawCode;
};

export const useRewards = (token) => {
  const [rewards, setRewards] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const transformReward = (response) => {
    if (!response.data || !response.data.responses) return [];
    return response.data.responses.map(item => ({
      id: item.certificate?.code || String(Math.random()),
      name: item.offer?.label || 'Reward',
      description: item.offer?.description || '',
      rawCode: item.certificate?.code || '',
      code: formatCode(item.certificate?.code || ''),
      endDate: item.usage_end_date,
      created: item.created_at
    }));
  };

  const fetchRewards = useCallback(async () => {
    if (!token) {
      setError('No token provided. Please ensure you have a valid URL with a token.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 401) {
        throw new Error('Unauthorized access. Please check your token.');
      }

      if (!response.ok) {
        throw new Error(`Failed to fetch rewards: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        const transformedRewards = transformReward(data);
        setRewards(transformedRewards);
        setError('');
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      Sentry.captureException(err);  // Capture fetch errors with Sentry
      navigate('/error', { state: { message: err.message || 'Failed to fetch rewards. Please try again later.' } });
    } finally {
      setLoading(false);
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchRewards();
  }, [fetchRewards]);

  return { rewards, error, loading, fetchRewards };
};
