import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RewardsPage from './pages/RewardsPage';
import ErrorPage from './pages/ErrorPage';
import RewardsTestPage from './pages/RewardsTestPage';
import './styles/rewards.css';
import './styles/test-page.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.SENTRY_DSN,  // Use environment variable for DSN
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.SENTRY_RELEASE,  // Optional: Use if you want to set release version
});

function App() {
  const isDevelopment = process.env.NODE_ENV === 'development';

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/rewards" />} />
          <Route path="/rewards" element={<RewardsPage />} />
          <Route path="/error" element={<ErrorPage />} />
          {isDevelopment && <Route path="/test" element={<RewardsTestPage />} />}
        </Routes>
      </Router>
    </Sentry.ErrorBoundary>
  );
}

export default App;
