import React from 'react';
import { motion } from 'framer-motion';
import * as Sentry from '@sentry/react';

const ErrorAlert = ({ message }) => {
  Sentry.captureMessage(message);  // Capture each alert message as an event in Sentry

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="error-container"
    >
      <div className="error-message">
        <h3 className="error-title">Error</h3>
        <p>{message}</p>
      </div>
    </motion.div>
  );
};

export default ErrorAlert;
